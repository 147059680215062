import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import TOC from '../../../components/TOC';
import ZipCodeField from '../ZipCodeField';
import SCLandingWrapper from '../../SCLandingWrapper';
import FindPlansLink from '../FindPlansLink';

import '../index.css';
import './index.css';

function Article({ siteConfig, serviceAddress }) {
  return (
    <div className="article-container">
      <ZipCodeField mobile buttonLabel={'100% Green Plans'} />
      <h1>
        <div>Switching to Renewable Energy to Reduce Your Carbon Footprint</div>
      </h1>
      <div className="page-container text-container green-energy">
        <h5 className="est-read-time">
          Published 03/16/2023 | Read time {'~ '}3 min
        </h5>
        <div className="page">
          <div className="inner">
            <TOC>
              <ZipCodeField
                intro={'Quickly find a 100% green electric plan for your home:'}
              />
            </TOC>
            <div className="article-text">
              <h2>Introduction</h2>
              <p>
                In recent years, there has been a growing awareness of the
                impact of energy production on the environment. As a result,
                many people are looking for ways to reduce their carbon
                footprint and support sustainable energy.
              </p>
              <p>
                One way to do this is by choosing 100% renewable energy options
                for your home.
              </p>
              <div className="parallax-image">
                <ParallaxProvider>
                  <Parallax speed={-10}>
                    <FontAwesomeIcon
                      className="top-icon leaf"
                      icon={['fa', 'leaf']}
                    />
                  </Parallax>
                </ParallaxProvider>
              </div>
              <h2>What is Green Energy?</h2>
              <p>
                Green energy, also known as renewable energy, is energy that is
                generated from sources that are naturally replenished and don't
                produce harmful emissions or waste products. These sources
                include wind, solar, hydropower, geothermal, and biomass.
              </p>
              <p>
                {' '}
                By contrast, traditional energy sources like coal, oil, and
                natural gas are finite resources that produce greenhouse gases
                and other pollutants when burned for energy.
              </p>
              <h2>How Does Green Energy Affect the Consumer?</h2>
              <p>
                Choosing green energy can have several benefits for consumers.
                First and foremost, it can help reduce your carbon footprint and
                support the transition to a more sustainable energy future. By
                using renewable energy sources, you can help reduce the amount
                of greenhouse gas emissions that are released into the
                atmosphere, which can help slow the effects of climate change.
              </p>
              <p>
                In addition to the environmental benefits, choosing green energy
                can also have financial benefits for consumers. Many renewable
                energy providers offer competitive rates that are often lower
                than traditional energy sources. Additionally, some states, like
                Texas, offer incentives and tax credits for consumers who
                install solar panels or other renewable energy systems.
              </p>
              <h2>
                How Does Choosing Green Energy Impact the Economy and
                Environment?
              </h2>
              <p>
                By supporting renewable energy sources, you can help create jobs
                in the growing green energy industry. According to the Bureau of
                Labor Statistics, employment in the renewable energy sector is
                projected to grow faster than average over the next decade.
              </p>
              <p>
                In addition to job creation, green energy can also help reduce
                our dependence on foreign oil and gas, which can have economic
                and national security benefits. By transitioning to renewable
                energy sources, we can also help reduce air and water pollution,
                protect wildlife habitats, and reduce our overall impact on the
                environment.
              </p>
              <h2>Several 100% Renewable Energy Options for Texas residents</h2>
              <p>
                If you are interested in choosing 100% renewable electrictity
                options for your home in Texas, there are several plans to
                choose from.
              </p>
              <FindPlansLink planType={'100% green'} />
            </div>
          </div>
        </div>
      </div>
      <SCLandingWrapper
        city={'dallas'}
        noHeader
        preFilter={{ quickFilter: 'green' }}
        preFilterLabel="100% green"
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
