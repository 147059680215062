import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import AddressSearchPopup from '../AddressSearch/AddressSearchPopup';

import './index.css';

function FindPlansLink({ siteConfig, serviceAddress, planType, text }) {
  const [showAddressSearch, setShowAddressSearch] = useState(null);

  return (
    <div className="find-plans-link">
      {serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.City &&
      serviceAddress.OfferList &&
      serviceAddress.OfferList.filter((offer) => offer.ProductTypeID === 6)
        .length ? (
        <p>
          <strong>
            {text ? (
              text
            ) : (
              <>
                Below are {planType} plans currently available here at{' '}
                {siteConfig.brand}.
              </>
            )}
          </strong>
        </p>
      ) : (
        <p>
          <a className="link" onClick={() => setShowAddressSearch(true)}>
            Click here
          </a>{' '}
          to find a {planType} plan that works for you today.
        </p>
      )}
      {showAddressSearch ? (
        <AddressSearchPopup
          mode="zip"
          closeFunc={() => setShowAddressSearch(null)}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FindPlansLink);
