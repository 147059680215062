import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/Green-Energy/index';

export default function ({}) {
  return (
    <>
      <SEO
        title={'Switching to Renewable Energy to Reduce Your Carbon Footprint'}
        description={
          'Does choosing a 100% renewable green energy electric plan really have an impact on the environment?'
        }
        brand={'sc'}
      />

      <Article />
    </>
  );
}
